import { KeyedMutator } from "swr";

import { API_URL } from "constants/api";
import { useSwr } from "hooks/useSwr";
import { useSwrMutation } from "hooks/useSwrMutation";

const useGetOrder = (
  orderId: string
): {
  order: Order;
  error: Error;
  mutate: KeyedMutator<Order>;
  trigger: (id: string) => Promise<Order | undefined>;
} => {
  const { data, error, mutate } = useSwr<Order>(orderId ? `${API_URL}/order/${orderId}` : null);
  // trigger(orderId)のように使い任意のタイミングでデータを取得できる
  const { trigger } = useSwrMutation<Order>(`${API_URL}/order/`);

  return { order: data, error, mutate, trigger };
};

export default useGetOrder;
