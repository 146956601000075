const setUpTagManager = () => {
  if (typeof window === "undefined") return;
  if (process.env.REACT_APP_TAG_ID == null || process.env.REACT_APP_TAG_ID === "") return;

  const tagId = process.env.REACT_APP_TAG_ID;

  const scriptNode = document.createElement("script");

  scriptNode.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','${tagId}');`;

  const noScriptNode = document.createElement("noscript");

  noScriptNode.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${tagId}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

  document.head.insertBefore(scriptNode, document.head.childNodes[0]);
  document.body.insertBefore(noScriptNode, document.body.childNodes[0]);

  console.log("GTM was set up");
};

export default setUpTagManager;
