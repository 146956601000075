import { isEmail } from "class-validator";
import {
  useEffect,
  useCallback,
  useState,
  lazy,
  Suspense,
  useMemo,
  ChangeEvent,
  SetStateAction,
  Dispatch,
} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import Loading from "components/Loading";
import color from "constants/color";
import { orderDetailDisplayPage, OrderDetailDisplayPage } from "constants/orderDetailDisplayPage";
import useAcceptEstimate from "hooks/api/useAcceptEstimate";
import useFindEstimates from "hooks/api/useFindEstimates";
import useGetOrder from "hooks/api/useGetOrder";
import useRejectEstimate from "hooks/api/useRejectEstimate";
import useShareEstimates from "hooks/api/useShareEstimates";
import useUpdateOrder from "hooks/api/useUpdateOrder";
import useErrorModalStatusContext from "hooks/useErrorModalStatusContext";
import { useInfoModal } from "hooks/useModal";
import { isBrowser, isTablet } from "utils/deviceDetect";
import { sortCollect, updateEstimateStatus } from "utils/estimateUtils";

// パーツ定義
const Board = styled.div<{ isMobile?: boolean }>`
  height: 100%;
  width: 100%;
  margin: ${({ isMobile }) => (!isMobile ? "24px 32px 32px" : "0")};
  display: flex;
  flex-direction: column;
  background-color: ${color.background};
`;
const LoadingWrap = styled.div`
  display: flex;
  height: 50vh;
  justify-content: center;
  align-items: center;
`;

const OrderEdit = lazy(() =>
  isBrowser
    ? import("pages/OrderDetail/pc/OrderEdit")
    : isTablet
    ? import("pages/OrderDetail/tb/OrderEdit")
    : import("pages/OrderDetail/sp/OrderEdit")
);

const Estimates = lazy(() =>
  isBrowser
    ? import("pages/OrderDetail/pc/Estimates")
    : isTablet
    ? import("pages/OrderDetail/tb/Estimates")
    : import("pages/OrderDetail/sp/Estimates")
);

const AcceptForm = lazy(() =>
  isBrowser
    ? import("pages/OrderDetail/pc/AcceptFrom")
    : isTablet
    ? import("pages/OrderDetail/tb/AcceptFrom")
    : import("pages/OrderDetail/sp/AcceptForm")
);

const RejectForm = lazy(() =>
  isBrowser
    ? import("pages/OrderDetail/pc/RejectForm")
    : isTablet
    ? import("pages/OrderDetail/tb/RejectForm")
    : import("pages/OrderDetail/sp/RejectForm")
);

type LocationState = Readonly<{
  redirectDisplayPage: OrderDetailDisplayPage;
}>;

export type ShareEstimatesActionsType = {
  updateEstimatesCheckList: (estimateId: string) => void;
  updateAllEstimateCheckList: (allCheck: boolean, setAllCheck: Dispatch<SetStateAction<boolean>>) => void;
  updateInput: (value: "email" | "remarks", e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  submitShareEstimates: () => Promise<void>;
  isErrorEmailsValidation: boolean;
};

const INIT_SHARE_ESTIMATES = { email: "", estimateIds: [], remarks: "", transformedEmails: [] };

const OrderDetail = () => {
  const location = useLocation();
  const state: LocationState = (location.state as LocationState) ?? {
    redirectDisplayPage: orderDetailDisplayPage.ESTIMATES,
  };
  const [displayPage, setDisplayPage] = useState<OrderDetailDisplayPage>(state.redirectDisplayPage);
  const params = useParams();
  const navigate = useNavigate();
  const { InfoModal, showInfoModal, hideInfoModal } = useInfoModal();
  const { updateOrder } = useUpdateOrder();
  const { acceptEstimate } = useAcceptEstimate();
  const { rejectEstimate } = useRejectEstimate();

  const { shareEstimates: share } = useShareEstimates();

  const { order: orderForDisplay, error: orderError, mutate: setOrderForDisplay } = useGetOrder(params.id ?? "");

  const { estimates, error: estimateError, mutate: setEstimates, isValidating } = useFindEstimates(params.id ?? "");

  const [orderForEdit, setOrderForEdit] = useState<Order | null>(null);
  const [infoModalTitle, setInfoModalTitle] = useState<string>("");
  const [estimateId, setEstimateId] = useState("");
  const [estimateForAcceptOrReject, setEstimateForAcceptOrReject] = useState<Estimate | null>(null);
  const [shareEstimatesStep, setShareEstimatesStep] = useState<ShareEstimatesStep>(0);
  const [shareEstimates, setShareEstimates] = useState<ShareEstimates>(INIT_SHARE_ESTIMATES);

  const emailsArray = useCallback(
    () => shareEstimates.email.trim().replaceAll(" ", "").split(","),
    [shareEstimates.email]
  );
  const transformedEmails = useCallback(() => emailsArray().filter((email) => email !== ""), [emailsArray]);
  const isErrorEmails = useCallback(
    () =>
      transformedEmails()
        .map((email) => isEmail(email))
        .includes(false),
    [transformedEmails]
  );

  const isErrorEmailsValidation = useMemo(() => {
    setShareEstimates((prev) => {
      return {
        ...prev,
        transformedEmails: transformedEmails(),
      };
    });
    return isErrorEmails();
  }, [isErrorEmails, transformedEmails]);

  const unansweredEstimates = sortCollect(estimates, "unanswered");
  const rejectedEstimates = sortCollect(estimates, "rejected");
  const acceptedEstimates = sortCollect(estimates, "accepted");
  const contractedEstimates = sortCollect(estimates, "contracted");
  const abortedEstimates = sortCollect(estimates, "aborted");
  const allEstimates = unansweredEstimates
    .concat(rejectedEstimates)
    .concat(acceptedEstimates)
    .concat(contractedEstimates)
    .concat(abortedEstimates)
    .filter((e: Estimate) => e.location !== null);

  const setOrder = useCallback(async () => {
    setOrderForEdit(orderForDisplay);
  }, [orderForDisplay, setOrderForEdit]);

  const toOrderPage = useCallback(() => {
    navigate("/order");
  }, [navigate]);

  const { showErrorModal } = useErrorModalStatusContext();

  useEffect(() => {
    if (orderError || estimateError) showErrorModal({ httpMethod: "get", onPostClose: toOrderPage });
    setOrder();
  }, [estimateError, navigate, orderError, setOrder, showErrorModal, toOrderPage]);

  const accept = useCallback(
    async (userRequest: string) => {
      if (!orderForDisplay?.id) return;
      const status = await acceptEstimate(orderForDisplay.id, estimateId, userRequest).catch(() =>
        showErrorModal({ httpMethod: "post" })
      );
      if (status === 200) {
        setEstimates(updateEstimateStatus(estimates, estimateId, "accepted"));
        setInfoModalTitle("契約を依頼しました");
        showInfoModal();
      }
    },
    [orderForDisplay, acceptEstimate, estimateId, setEstimates, estimates, showInfoModal, showErrorModal]
  );

  const reject = useCallback(
    async (rejectReason: RejectReason) => {
      if (!orderForDisplay?.id) return;
      const status = await rejectEstimate(orderForDisplay.id, estimateId, rejectReason).catch(() =>
        showErrorModal({ httpMethod: "post" })
      );
      if (status === 200) {
        setEstimates(updateEstimateStatus(estimates, estimateId, "rejected"));
        setInfoModalTitle("契約を見送りました");
        showInfoModal();
      }
    },
    [orderForDisplay, rejectEstimate, estimateId, setEstimates, estimates, showInfoModal, showErrorModal]
  );

  const update = useCallback(
    async (order: Order) => {
      if (!order?.id) return;
      const status = await updateOrder(order).catch(() => showErrorModal({ httpMethod: "put" }));
      if (status === 200) {
        setOrderForDisplay(order);
        setInfoModalTitle("依頼内容を変更しました");
        showInfoModal();
      }
    },
    [updateOrder, setOrderForDisplay, showInfoModal, showErrorModal]
  );

  const onClickReject = (estimateId: string) => {
    setEstimateId(estimateId);
    const estimate = estimates.find((e) => e.id === estimateId);
    if (estimate) {
      setEstimateForAcceptOrReject(estimate);
    }
    setDisplayPage(orderDetailDisplayPage.REJECT_ESTIMATE);
  };

  const onClickAccept = (estimateId: string) => {
    setEstimateId(estimateId);
    const estimate = estimates.find((e) => e.id === estimateId);
    if (estimate) {
      setEstimateForAcceptOrReject(estimate);
    }
    setDisplayPage(orderDetailDisplayPage.ACCEPT_ESTIMATE);
  };

  const handleAccept = async (userRequest: string) => {
    await accept(userRequest);
    setEstimateId("");
  };
  const handleReject = async (rejectReason: RejectReason) => {
    await reject(rejectReason);
    setEstimateId("");
  };

  const handleComplete = () => {
    hideInfoModal();
    if (displayPage === orderDetailDisplayPage.EDIT_ORDER) return toOrderPage();
    setDisplayPage(orderDetailDisplayPage.ESTIMATES);
  };

  const updateEstimatesCheckList = useCallback(
    (estimateId: string) => {
      setShareEstimates((prev) => {
        const updatedEstimateIds = prev.estimateIds.includes(estimateId)
          ? prev.estimateIds.filter((_estimateId) => _estimateId !== estimateId)
          : [...prev.estimateIds, estimateId];
        return {
          ...prev,
          estimateIds: updatedEstimateIds,
        };
      });
    },
    [setShareEstimates]
  );

  const updateAllEstimateCheckList = useCallback(
    (allCheck: boolean, setAllCheck: Dispatch<SetStateAction<boolean>>) => {
      setShareEstimates((prev) => {
        return {
          ...prev,
          estimateIds: allCheck ? [] : estimates.map((estimate) => estimate.id),
        };
      });
      setAllCheck(!allCheck);
    },
    [setShareEstimates, estimates]
  );

  const submitShareEstimates = useCallback(async () => {
    const status = await share(shareEstimates).catch(() => showErrorModal({ httpMethod: "post" }));
    if (status === 200) {
      setShareEstimatesStep(3);
      setShareEstimates(INIT_SHARE_ESTIMATES);
    }
  }, [shareEstimates, share, showErrorModal]);

  const updateInput = useCallback(
    (value: "email" | "remarks", e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setShareEstimates((prev) => {
        return {
          ...prev,
          [value]: e.target.value,
        };
      });
    },
    [setShareEstimates]
  );

  const shareEstimatesActions = {
    updateEstimatesCheckList,
    updateAllEstimateCheckList,
    updateInput,
    submitShareEstimates,
    isErrorEmailsValidation,
  };

  const onClickShare = () => {
    setShareEstimatesStep(1);
  };

  const setShareEstimatesStepResetScroll = (step: ShareEstimatesStep) => {
    setShareEstimatesStep(step);
    window.scrollTo(0, 0);
  };

  const canAccept = (estimateId: string) => {
    const estimate = estimates.find((e) => e.id === estimateId);
    if (estimate == null) return false;
    return estimate.status === "unanswered" || estimate.status === "rejected";
  };

  const canReject = (estimateId: string) => {
    const estimate = estimates.find((e) => e.id === estimateId);
    if (estimate == null) return false;
    return estimate.status === "unanswered";
  };

  return (
    <>
      <Board isMobile={!isBrowser && !isTablet}>
        {displayPage === orderDetailDisplayPage.ESTIMATES && orderForDisplay && estimates && !isValidating && (
          <Suspense fallback={<Loading />}>
            <Estimates
              order={orderForDisplay}
              estimates={allEstimates}
              editHandler={() => setDisplayPage(orderDetailDisplayPage.EDIT_ORDER)}
              acceptHandler={onClickAccept}
              rejectHandler={onClickReject}
              toOrderList={toOrderPage}
              shareEstimatesStep={shareEstimatesStep}
              setShareEstimatesStep={setShareEstimatesStepResetScroll}
              shareEstimates={shareEstimates}
              setShareEstimates={setShareEstimates}
              shareEstimatesActions={shareEstimatesActions}
              onClickShare={onClickShare}
            />
          </Suspense>
        )}
        {displayPage === orderDetailDisplayPage.EDIT_ORDER && orderForEdit && (
          <Suspense fallback={<Loading />}>
            <OrderEdit order={orderForEdit} onClose={toOrderPage} submit={update} />
          </Suspense>
        )}
        {displayPage === orderDetailDisplayPage.REJECT_ESTIMATE &&
          estimateForAcceptOrReject &&
          orderForDisplay.location && (
            <Suspense fallback={<Loading />}>
              <RejectForm
                estimate={estimateForAcceptOrReject}
                orderLocation={orderForDisplay.location}
                rejectHandler={handleReject}
                cancelHandler={() => setDisplayPage(orderDetailDisplayPage.ESTIMATES)}
                canReject={canReject}
              />
            </Suspense>
          )}
        {displayPage === orderDetailDisplayPage.ACCEPT_ESTIMATE &&
          estimateForAcceptOrReject &&
          orderForDisplay.location && (
            <Suspense fallback={<Loading />}>
              <AcceptForm
                estimate={estimateForAcceptOrReject}
                orderLocation={orderForDisplay.location}
                acceptHandler={handleAccept}
                onClick={() => setDisplayPage(orderDetailDisplayPage.ESTIMATES)}
                canAccept={canAccept}
              />
            </Suspense>
          )}
        {!orderForDisplay ||
          (!estimates && (
            <LoadingWrap>
              <Loading />
            </LoadingWrap>
          ))}
      </Board>
      <InfoModal title={infoModalTitle} onClick={handleComplete} />
    </>
  );
};

export default OrderDetail;
