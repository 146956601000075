import axios from "axios";
import { Auth } from "firebase/auth";
import useSWRMutation from "swr/mutation";

import useFirebaseContext from "hooks/useFirebaseContext";

type AxiosError = {
  error: string;
  message: string;
  statusCode: number;
};

const isDevelopEnv = ["local", "dev", "staging"].includes(process.env.REACT_APP_ENV ?? "");

/**
 * イベント発火時にSWRを使用してAPIを呼び出す
 * @param url: string
 * @param auth: Auth
 * @returns
 */
export const fetcher = async (url: string | null, auth: Auth) => {
  if (!url) return;
  const token = await auth?.currentUser?.getIdToken();

  axios.interceptors.response.use(
    (response) => {
      if (isDevelopEnv) console.log("レスポンスデータ：", response.data);
      return response;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const response = await axios
    .get(url, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .catch((error) => {
      if (isDevelopEnv) console.error("エラー：", error?.response?.data ?? error);
      throw error;
    });

  return response.data;
};

/**
 * useSWRMutationを使用する
 * @param baseUrl - ベースとなるURL
 * @returns data, error, isMutating, trigger
 */
export const useSwrMutation = <T>(
  baseUrl: string | null
): {
  data: T | undefined;
  error: AxiosError | undefined;
  isMutating: boolean;
  // trigger に (id?: string) を渡せるようにして Promise<T> を返す
  trigger: (id?: string) => Promise<T | undefined>;
} => {
  const { auth } = useFirebaseContext();

  // useSWRMutationのfetcher: 第二引数 { arg } でtrigger呼び出し時の引数を受け取る
  const mutationFetcher = async ([url, auth]: [string | null, Auth], { arg: id }: { arg?: string }) => {
    if (!url) throw new Error("URL is required");
    // idがあれば末尾に付与するなどの用途に応じて実装
    const finalUrl = id ? `${url}/${id}` : url;
    return fetcher(finalUrl, auth) as Promise<T>;
  };

  const { data, error, isMutating, trigger } = useSWRMutation<T, AxiosError, [string | null, Auth], string | undefined>(
    [baseUrl, auth],
    mutationFetcher
  );

  return { data, error, isMutating, trigger };
};
